<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container">
      <div class="weui-tab">
        <div class="weui-tab__bd">
          <div
            id="tab2"
            class="
              weui-tab__bd-item
              public-coin-list-all
              weui-tab__bd-item--active
            "
          >
            <div class="public-areas margin-bottom-10">
              <div class="weui-cells margin-top-0 public-coin-list">
                <div class="weui-cell" v-for="(item, index) in list" :key="index">
                  <div class="weui-cell__bd">
                    <p>{{item.remark}}</p>
                    <p class="remark">{{item.createTime | timeFormat('YYYY-MM-DD HH:mm:ss')}}</p>
                  </div>
                  <div class="weui-cell__ft">
                    <p class="text-red">
                      {{item.scoreChanged}} <i class="icon icon-public-coins coin text-red"></i>
                    </p>
                    <p class="remark">
                      余额：{{item.scoreAfterChange}} <i class="icon icon-public-coins coin remark"></i>
                    </p>
                  </div>
                </div>
                <!-- <div class="weui-cell">
                  <div class="weui-cell__bd">
                    <p>【问卷】25224号调查，任务码：10m1l1in</p>
                    <p class="remark">2022-02-20 21:40:06</p>
                  </div>
                  <div class="weui-cell__ft">
                    <p class="text-green">
                      +2 <i class="icon icon-public-coins coin text-green"></i>
                    </p>
                    <p class="remark">
                      余额：14
                      <i class="icon icon-public-coins coin remark"></i>
                    </p>
                  </div>
                </div>
                <div class="weui-cell">
                  <div class="weui-cell__bd">
                    <p>【问卷】25241号调查，任务码：zd205kuw</p>
                    <p class="remark">2022-02-20 21:36:57</p>
                  </div>
                  <div class="weui-cell__ft">
                    <p class="text-green">
                      +5 <i class="icon icon-public-coins coin text-green"></i>
                    </p>
                    <p class="remark">
                      余额：12
                      <i class="icon icon-public-coins coin remark"></i>
                    </p>
                  </div>
                </div>
                <div class="weui-cell">
                  <div class="weui-cell__bd">
                    <p>【问卷】25113号调查，任务码：27gvggiq</p>
                    <p class="remark">2022-02-20 21:35:15</p>
                  </div>
                  <div class="weui-cell__ft">
                    <p class="text-green">
                      +2 <i class="icon icon-public-coins coin text-green"></i>
                    </p>
                    <p class="remark">
                      余额：7 <i class="icon icon-public-coins coin remark"></i>
                    </p>
                  </div>
                </div>
                <div class="weui-cell">
                  <div class="weui-cell__bd">
                    <p>【问卷】24955号调查，任务码：7y8dx5f7</p>
                    <p class="remark">2022-02-20 16:37:06</p>
                  </div>
                  <div class="weui-cell__ft">
                    <p class="text-green">
                      +5 <i class="icon icon-public-coins coin text-green"></i>
                    </p>
                    <p class="remark">
                      余额：5 <i class="icon icon-public-coins coin remark"></i>
                    </p>
                  </div>
                </div> -->
              </div>
            </div>
            <div :class="['weui-loadmore', 'public-loadmore', {'hidden' : !isShowBottomLoading}]">
              <i class="weui-loading"></i>
              <span class="weui-loadmore__tips">正在加载</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tabbar :selectedVal="'users'"></tabbar>
  </div>
</template>

<script>
import throttle from 'lodash/throttle'
import { scoreRecord } from '../api/index'
import tabbar from '../components/tabbar/index.vue'
export default {
  name: "coin",
  components: {
    tabbar
  },
  data () {
    return {
      pageNum: 1,
      pageSize: 20,
      pageCount: 1, //	总共页数
      list: [], // 明细列表
      isShowBottomLoading: false
    }
  },
  watch: {
    pageNum: function () {
      this.doGscoreRecord()
    }
  },
  created () {
    this.doGscoreRecord()
    this.listenScroll()
  }, 
  methods: {
    // 提交用户信息
    async doGscoreRecord () {
      let params = {
        param: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
      let res = {}
      try {
        this.isShowBottomLoading = true
        res = await scoreRecord(params)
        this.isShowBottomLoading = false
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result
          const list = result.userScoreRecordList
          this.list.push(...list)
          this.pageCount = result.pageCount
          
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.isShowBottomLoading = false
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    // 页面已经滑动到底部
    pageToBottom () {
      if (this.pageNum < this.pageCount) {
        this.pageNum++
      }
    },
    listenScroll () {
      const _this = this
      window.onscroll = throttle(function (e) {
        console.log('e:', e)
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        let screenH = document.documentElement?.clientHeight || window.innerHeight
        let bodyH = document.body.scrollHeight
        if ((scrollTop + screenH + 10 - bodyH) > 0 ) {
          console.log('页面已经滑动到底部')
          _this.pageToBottom()
        }
      }, 500)
    }
  }
};
</script>

<style>
.public-container{
  margin: unset;
}
</style>
