<template>
  <div class="weui-tabbar public-tarbar">
    <a
      href="javascript:void(0)"
      @click="$router.push({ name: 'home' })"
      :class="['weui-tabbar__item', {'weui-bar__item--on' : selectedVal === 'home'}]"
    >
      <div class="weui-tabbar__icon">
        <i class="icon icon-public-detail"></i>
      </div>
      <p class="weui-tabbar__label">问卷调查</p>
    </a>
    <a
      href="javascript:void(0)"
      @click="$router.push({ name: 'users' })"
      :class="['weui-tabbar__item', {'weui-bar__item--on' : selectedVal === 'users'}]"
    >
      <div class="weui-tabbar__icon">
        <i class="icon icon-public-center"></i>
      </div>
      <p class="weui-tabbar__label">个人中心</p>
    </a>
  </div>
</template>
<script>
export default {
  props: {
    selectedVal: {
      type: String
    }
  }
  
}
</script>
<style lang="css" scoped>
.weui-tabbar{
  width: 100%;
  bottom: 0;
}

</style>